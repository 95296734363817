// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { useUserStore } from "@/stores/user";
import {
  getFirestore,
  collection,
  query,
  startAfter,
  limit,
  doc,
  getDoc,
  getDocs,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCehmy3sKndniodmGI3fl_yZiGAzzj883c",
  authDomain: "film-point.firebaseapp.com",
  projectId: "film-point",
  storageBucket: "film-point.appspot.com",
  messagingSenderId: "884942742706",
  appId: "1:884942742706:web:475f538dc1bc747962d5ab",
  measurementId: "G-DTT4YDKTE5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth();
const db = getFirestore(app);

const getCurrentUser = () => {
  const userStore = useUserStore();
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      userStore.user = user;
      resolve(user);
    }, reject);
  });
};

export const getAdmins = async () => {
  const docRef = doc(db, "admin", "groups");
  const adminsDoc = await getDoc(docRef);
  return adminsDoc.data()?.admin;
};

export async function getFirstLocationsPage() {
  const first = query(
    collection(db, "locations"),
    where("approved", "==", true),
    limit(25)
  );

  return await getDocs(first);
}

export async function getLocations(lastLocation: any) {
  const next = query(
    collection(db, "locations"),
    where("approved", "==", true),
    startAfter(lastLocation),
    limit(25)
  );

  return await getDocs(next);
}

export { auth, getCurrentUser, db, storage };
